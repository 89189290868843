<template>
    <div class="ml-10 mr-10">
        <TitleBar>{{ $t("menu.invoices") }}
            <v-btn
                class="mx-2"
                color="secondary"
                fab
                small
                to="/incomes/invoices/new"
            >
                <v-icon>
                    mdi-plus
                </v-icon>
            </v-btn>
        </TitleBar>

        <div v-if="this.$route.query.contact !== undefined">
            <div class="pl-5 pb-5 d-flex">
                <v-icon>mdi-account</v-icon>
                <DynamicElement :uuid="this.$route.query.contact" class="pl-2" type="contact"></DynamicElement>
            </div>

            <List :api-url="'/contacts/' + this.$route.query.contact + '/invoices'"
                  :fields="['invoice_number', 'contact', 'total', 'status', 'emitted']"
                  api-key="invoices"
                  redirect-uri="/incomes/invoices"
            ></List>
        </div>

        <List v-else :fields="['invoice_number', 'contact', 'total', 'status', 'emitted']"
              api-key="invoices"
              api-url="/incomes/invoices"></List>

    </div>
</template>

<script>

import List from "../../components/List";
import TitleBar from "@/components/TitleBar.vue";
import DynamicElement from "@/components/DynamicElement.vue";

export default {
    name: "Dashboard",
    components: {DynamicElement, TitleBar, List},
    data() {
        return {};
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.invoices"),
                to: "/incomes/invoices",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>